<template>
  <div class="position-data">
    <div class="currency-btn">
      <el-radio-group v-model="radio">
        <el-radio-button label="BTC"></el-radio-button>
        <el-radio-button label="ETH"></el-radio-button>
      </el-radio-group>
    </div>
    <b-card no-body>
      <b-card-header>
        <b-card-title class="mb-50">
          <div class="header-title">
            <a href="https://lib.rta-finance.com/#/article?id=621" target="_blank">
              期权痛点</a>

            <span>（Open Interest By Strike Price）</span>
          </div>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <div class="select-container" style="display: flex">
          <div class="chart-select filter">
            <div class="tittle-diff">价格区间</div>
            <div class="input-container">
              <el-input v-model="PainPoints.priceMin" onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                placeholder="请输入数字" @input="minClick($event)" class="price"></el-input>
              <span> - </span>
              <el-input v-model="PainPoints.priceMax" @input="maxClick($event)"
                onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" placeholder="请输入数字" class="price"></el-input>
            </div>
          </div>
          <div class="chart-option filter">
            <el-select v-model="PainPoints.date" placeholder="" @change="showChange($event)">
              <el-option v-for="(item, index) in dataOptions" :key="index" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="container-body">
          <BarCharts id="chart" ref="chart" class="chart" :option="earningOption" />
        </div>
        <div class="data-color-card">
          <div class="color-card">
            <div class="row-title call-open-interest">Call Open Interest</div>
            <div class="row-value">
              {{
                formatNum(parseFloat(this.priceArr.callOpenInterest).toFixed(2))
              }}
            </div>
          </div>
          <div class="color-card">
            <div class="row-title put-open-interest">Put Open Interest</div>
            <div class="row-value">
              {{
                formatNum(parseFloat(this.priceArr.putOpenInterest).toFixed(2))
              }}
            </div>
          </div>
          <div class="color-card">
            <div class="row-title put">Call/Put Ratio</div>
            <div class="row-value">
              {{ formatNum(parseFloat(this.priceArr.ratio).toFixed(2)) }}
            </div>
          </div>
          <div class="color-card">
            <div class="row-title total-open-interest">Total Open Interest</div>
            <div class="row-value">
              {{
                formatNum(
                  parseFloat(this.priceArr.totalOpenInterest).toFixed(2)
                )
              }}
            </div>
          </div>
          <div class="color-card">
            <div class="row-title notional-value">Notional Value</div>
            <div class="row-value">
              <span>$</span>
              {{
                formatNum(parseFloat(this.priceArr.notionalValue).toFixed(2))
              }}
            </div>
          </div>
          <div class="color-card" v-if="maxPriceShow" style="margin-right: 0">
            <div class="row-title max-strike-price">Max Pain Price</div>
            <div class="row-value">
              {{ formatNum(parseFloat(this.priceArr.maxPainPrice).toFixed(2)) }}
            </div>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title class="mb-50">
          <div class="header-title">期权持仓分布</div>
        </b-card-title>
      </b-card-header>
      <b-card-body style="height: 1047px">
        <b-row class="">
          <b-col lg="6">
            <div class="container-body-distribution">
              <div class="container-body-title">
                <a href="https://lib.rta-finance.com/#/article?id=622" target="_blank">
                  按到期日</a>
                <span>（Open Interest By Strike Price）</span>
              </div>
              <div class="charts">
                <Chart ref="chart1" :option="expirationOption" :loading="loading" />
              </div>
            </div>
          </b-col>

          <b-col lg="6">
            <div class="container-body-distribution">
              <div class="container-body-title">
                <a href="https://lib.rta-finance.com/#/article?id=623" target="_blank">按执行价格</a>

                <span>（Open Interest By Strikes）</span>
              </div>
              <div class="charts">
                <Chart ref="chart2" class="chart" :option="strikesOption" :loading="loading1" />
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="">
          <b-col lg="12">
            <div class="container-body-distribution" style="height: 100%">
              <div class="container-body-title">
                <a href="https://lib.rta-finance.com/#/article?id=624" target="_blank">按天变化</a>

                <span>（Open Interest (Prev. day）</span>
              </div>
              <div class="select-container">
                <div class="chart-option filter">
                  <el-select v-model="interestArr.prev" @change="positionChange($event)" placeholder="">
                    <el-option v-for="item in valueOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="chart-option filter">
                  <el-select v-model="interestArr.day" @change="dateChange($event)" placeholder="">
                    <el-option v-for="item in interestOption" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="charts" style="margin-top: 10px">
                <Chart class="chart" ref="chart3" :option="assetsOption" :loading="loading2" />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
//封装的echarts toolbox
import * as myToolFn from "@/utils/echartsFullScreen";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
} from "bootstrap-vue";
import {
  fromatterPieOptions,
  number2String,
  graphic,
  graphicBig,
  SPLIT_LINE,
  NOT_DATA_OPTION,
} from "../../../utils/chart";
import Chart from "../../charts-and-maps/charts/chart.vue";
import BarCharts from "../../charts-and-maps/charts/barCharts.vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    Chart,
    BarCharts,
  },
  // props: {
  //   radio: { type: String },
  //   name: { type: String },
  //   activeNameTab: {
  //     type: String,
  //     required: true,
  //   },
  // },
  data() {
    return {
      radio: "BTC",
      name: "a",
      activeNameTab: "a",
      PainPoints: {
        priceMin: "",
        priceMax: "",
        date: "all",
      },
      priceArr: {
        callOpenInterest: "",
        notionalValue: "",
        putOpenInterest: "",
        ratio: "",
        totalOpenInterest: "",
        maxPainPrice: "",
      },
      callInfos: {
        open_sum: [],
        option_price: [],
        value: [],
      },
      putInfos: {
        open_sum: [],
        option_price: [],
        value: [],
      },
      queryDate: [],
      painPriceSet: [],
      dataOptions: [],
      assetsOption: {},
      earningOption: {},
      expirationOption: {},
      strikesOption: {},

      dayOptions: [
        {
          value: "1h",
          label: "1小时",
        },
        {
          value: "2h",
          label: "2小时",
        },
        {
          value: "4h",
          label: "4小时",
        },
        {
          value: "6h",
          label: "6小时",
        },
        {
          value: "12h",
          label: "12小时",
        },
        {
          value: "1d",
          label: "1天",
        },
      ],
      interestArr: {
        prev: "open_sum",
        day: "",
      },
      optionText: "",
      valueOptions: [
        {
          value: "open_sum",
          label: "# of contracts",
        },
        {
          value: "value",
          label: "$ value",
        },
      ],
      interestOption: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "day",
          label: "日",
        },
        {
          value: "week",
          label: "周",
        },
        {
          value: "month",
          label: "月",
        },
        {
          value: "year",
          label: "年",
        },
      ],

      cyData: [],
      yShow: false,
      maxPriceShow: false,
      currency: "",
      painPriceSet: [],
      positionXData: [],
      positionOpenSum: [],
      positionValue: [],
      positionAllValue: [],
      expirationXData: [],
      expirationPut: [],
      expirationCall: [],
      expirationCallsValue: [],
      expirationPutsValue: [],
      strikesXData: [],
      strikesPut: [],
      strikesCall: [],
      loading: true,
      loading1: true,
      loading2: true,
    };
  },
  watch: {
    // activeNameTab(newValue) {
    //   this.getListDataPageAction(this.name, newValue, this.radio);

    // },
    activeNameTab(newValue) {
      this.getListDataPageAction(this.name, newValue, this.radio);
    },
    radio: {
      handler(newValue) {
        this.getListDataPageAction(this.name, this.activeNameTab, newValue);
      },
      immediate: true,
    },
  },
  created() {
    // this.getPostionData();
    // this.getPostionExpirationData();
    // this.getPostionStrikesData();
    this.findInterestByExpiration();
    this.findInterestByStrikesn();
    this.assetsChange();
  },
  beforeUnmount() { },
  beforeDestroy() {
    if (this.chart != null) {
      this.chart.dispose();
      this.chart = null;
    }
  },
  mounted() { },
  methods: {
    //期权痛点时间做筛选，price是否显示事件
    showChange(item) {
      this.PainPoints.date = item;

      if (this.PainPoints.date === "all") {
        this.maxPriceShow = false;
      } else {
        this.maxPriceShow = true;
      }
      this.getPainpointData();
      this.findProfitForm();
    },
    minClick(item) {
      this.PainPoints.priceMin = item;
      this.getPainpointData();
    },
    maxClick(item) {
      this.PainPoints.priceMax = item;
      this.getPainpointData();
    },
    //判断一下只执行当前选中的标签页
    getListDataPageAction(name, activeNameTab, radio) {
      if (name === activeNameTab && !this.isRepetition) {
        this.isRepetition = true;
        this.getPainpointData(radio);
        this.getPostionExpirationData(radio);
        this.getPostionStrikesData(radio);
        this.getPostionData(radio);
      }
    },
    //获取期权痛点全部数据
    getPainpointData() {
      const params = {
        currency: this.radio,
        priceMin: this.PainPoints.priceMin,
        priceMax: this.PainPoints.priceMax,
        queryDate: this.PainPoints.date == "all" ? "" : this.PainPoints.date,
      };
      this.axios
        .get("https://trade.rta-finance.com/trade/deribitoption/painpoint/", {
          params,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.isRepetition = false;
            let tempData = res.data.data;
            this.priceArr = tempData;
            this.dataOptions = [];
            let dates = tempData.queryDate;
            dates.sort((a, b) => Date.parse(a) - Date.parse(b));
            dates.forEach((item) => {
              const timeArr = this.formatDate(Date.parse(item), "yyyy-MM-dd");

              const positionObj = {
                value: item,
                label: timeArr,
              };

              this.dataOptions.push(positionObj);
            });

            // this.dataOptions.sort(object);
            this.dataOptions.unshift({
              value: "all",
              label: "All Expirations",
            });
            this.callInfos.open_sum = tempData.callInfos.map((v) =>
              v.open_sum.toFixed(2)
            );

            this.callInfos.option_price = tempData.callInfos.map(
              (v) => v.option_price
            );
            this.callInfos.Value = tempData.callInfos.map((v) =>
              v.value.toFixed(2)
            );
            this.putInfos.open_sum = tempData.putInfos.map((v) =>
              v.open_sum.toFixed(2)
            );
            this.putInfos.option_price = tempData.putInfos.map((v) =>
              v.option_price.toFixed(2)
            );
            this.putInfos.value = tempData.putInfos.map((v) =>
              v.value.toFixed(2)
            );
            //console.log(111, tempData.hasOwnProperty("painPriceSet"));
            if (tempData.hasOwnProperty("painPriceSet") == true) {
              this.painPriceSet = tempData.painPriceSet.map((v) => v.earnings);
            } else {
              this.painPriceSet = [];
            }
            //console.log(this.putInfos, this.callInfos);
            this.findProfitForm(this.callInfos.Value, this.putInfos.value);
          } else {
            this.callInfos.open_sum = [];
            this.priceArr.callInfos.option_price = [];
            this.priceArr.callInfos.value = [];
            this.putInfos.open_sum = [];
            this.putInfos.option_price = [];
            this.putInfos.value = [];
          }
        })
        .catch((err) => { });
    },
    // // 痛点数据展示
    findProfitForm() {
      let cyData = this.painPriceSet;
      const xData = this.callInfos.option_price;
      const callSet = this.callInfos.open_sum;
      const callValue = this.callInfos.value;
      const putSet = this.putInfos.open_sum;
      const putValue = this.putInfos.value;
      var min = cyData[0];
      var index = 0;
      for (var i = 0; i < cyData.length; i++) {
        if (min > cyData[i]) {
          min = cyData[i];
          index = i;
        }
      }
      var maxNum = xData[index];

      if (cyData.length > 0) {
        this.earningOption = {
          backgroundColor: "#33353e",
          tooltip: {
            trigger: "item",
            borderWidth: "0", //边框宽度设置1
            borderColor: "none", //设置边框颜色
            padding: 20,

            textStyle: {
              fontSize: 12,
              color: "rgba(209, 212, 220, 1)", //设置文字颜色
            },

            formatter: (params) => {
              const dataIndex = params.dataIndex; // 获取鼠标移到的柱子的索引
              const seriesName = params.seriesName; // 获取柱子所属系列的名称
              const callValue = this.formatNum(this.callInfos.Value);
              const putValue = this.formatNum(this.putInfos.value);
              const value =
                seriesName === "Calls"
                  ? this.formatNum(callSet[dataIndex])
                  : seriesName === "Puts"
                    ? this.formatNum(putSet[dataIndex])
                    : this.formatNum(cyData[dataIndex]); // 获取柱子的值
              const notionalValue =
                seriesName === "Calls"
                  ? callValue[dataIndex]
                  : putValue[dataIndex]; // 获取柱子的值
              const result = `Strike Price:${this.formatNum(params.name)}<br>`;
              const notionalV = `Notional Value[USD]: $${this.formatNum(
                notionalValue
              )}<br>`;

              return `
                <div>
                  <div >
                  ${result}${seriesName}: ${value}<br>${notionalV}
                  </div>
                  

                </div>
              `;
            },
            extraCssText: "background:rgba(61, 63, 71, 1);",
          },
          toolbox: {
            top: 20,
            bottom: 120,
            right: "2%",
            iconStyle: {
              borderColor: "#D1D4DC",
            },
            emphasis: {
              iconStyle: {
                borderColor: "#edbd88", // 鼠标悬停时的图标颜色
              },
            },
            feature: {
              saveAsImage: {
                title: "Download Chart",
                icon: "path://M746.666667 597.333333a149.333333 149.333333 0 0 0-79.36 23.04l-123.093334-123.093333 333.013334-332.8a21.333333 21.333333 0 0 0 0-30.293333 21.333333 21.333333 0 0 0-30.08 0L514.133333 467.2 181.12 134.186667a21.333333 21.333333 0 0 0-30.08 0 21.333333 21.333333 0 0 0 0 30.293333l333.013333 332.8-124.8 124.8A147.626667 147.626667 0 0 0 277.333333 597.333333a149.333333 149.333333 0 1 0 113.92 53.12l122.88-122.88 120.533334 120.533334A149.333333 149.333333 0 1 0 746.666667 597.333333zM277.333333 853.333333a106.666667 106.666667 0 1 1 106.666667-106.666666 106.666667 106.666667 0 0 1-106.666667 106.666666z m469.333334 0a106.666667 106.666667 0 1 1 106.666666-106.666666 106.666667 106.666667 0 0 1-106.666666 106.666666z",
              },
              restore: {
                show: true,
                title: "Restore",
              },
              myFull: myToolFn.myFull(() => this.$refs.chart.$el),
              myDownloadCSV: myToolFn.downloadCSV(() => [
                this.$refs.chart.option.xAxis,
                ...this.$refs.chart.option.series.map((v) => v.data),
              ]),
            },
          },
          grid: {
            top: 90,
            left: "2%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          legend: {
            bottom: 100,
            top: 20,
            textStyle: {
              fontSize: 12,
              color: "#D1D4DC",
            },
            padding: [0, 32, 0, 0],
            itemHeight: 12,
            itemWidth: 12,
            itemGap: 40,
            data: [
              {
                name: "Calls",
                icon: "roundRect",
              },
              {
                name: "Puts",
                icon: "roundRect",
              },
              {
                name: "Total Intrinsic Value",
                icon: "circle",
                itemHeight: 8,
                itemWidth: 8,
              },
            ],
          },
          xAxis: [
            {
              data: xData,
              type: "category",
              offset: 12,

              axisLabel: {
                rotate: 60,
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              nameGap: 25,
              name: "Open Interest",
              axisLabel: {
                formatter: function (v) {
                  if (v > 1000 && v <= 1000000) {
                    return v / 1000 + "K";
                  } else if (v > 1000000 && v <= 1000000000) {
                    return v / 1000000 + "M";
                  } else if (v > 1000000000) {
                    return v / 1000000000 + "B";
                  } else {
                    return v;
                  }
                  // return v + 'K';
                },
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
                margin: 20,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(159, 159, 159, 0.1)",
                },
              },
            },
            {
              type: "value",
              nameGap: 25,
              show: true,
              name: "Intrinsic Value at Expiration[USD]",
              // interval: 5,
              axisLabel: {
                formatter: function (v) {
                  if (v > 1000 && v <= 1000000) {
                    return v / 1000 + "K";
                  } else if (v > 1000000 && v <= 1000000000) {
                    return v / 1000000 + "M";
                  } else if (v > 1000000000) {
                    return v / 1000000000 + "B";
                  } else {
                    return v;
                  }
                  // return v + 'K';
                },
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
                margin: 20,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(255, 255, 255, 0.2)",
                },
              },
            },
          ],
          series: [
            {
              name: "Calls",
              type: "bar",
              data: callSet,
              itemStyle: {
                color: "rgba(74, 181, 232, 1)",
              },
              emphasis: {
                focus: "series",
              },
            },
            {
              name: "Puts",
              type: "bar",
              data: putSet,
              itemStyle: {
                color: "rgba(234, 204, 174, 1)",
              },
              emphasis: {
                focus: "series",
              },
            },
            {
              name: "Total Intrinsic Value",
              type: "line",
              yAxisIndex: 1,
              data: cyData,
              emphasis: {
                focus: "series",
              },
              markLine: {
                symbol: [],
                symbolSize: [14, 17],
                label: {
                  show: true,
                  padding: [0, 0, 5, 0],
                  color: "#D8D8D8",
                  fontSize: 12, // 字体大小
                  fontWeight: "normal",
                  fontFamily: "Roboto-Regular, Robot", // 字体系列
                  formatter() {
                    return `Max Strike Price：${maxNum}`;
                  },
                },
                data: [{ xAxis: maxNum?.toString() }],
                lineStyle: {
                  width: 1,
                  color: "#979797",
                },
              },
              symbol: "circle", //将小圆点改成实心 不写symbol默认空心
              symbolSize: 6, //小圆点
              lineStyle: {
                normal: {
                  color: " ",
                  width: 0,
                },
                //hover状态
                emphasis: {
                  color: "rgba(0, 102, 210, 1)",
                  width: 1.5,
                },
              },
              itemStyle: {
                color: "rgba(0, 102, 210, 1)",
              },
            },
          ],
          graphic: [
            {
              type: "image",
              id: "logo",
              right: "center",
              bottom: "center",
              z: 0,
              bounding: "all",
              style: {
                image: "echarts-bg_yYgTFo1.png", // 必须是https开头的图片路径地址
                width: 400,
                height: 68,
              },
            },
          ],
        };
      } else {
        this.earningOption = {
          backgroundColor: "#33353e",
          tooltip: {
            trigger: "item",
            borderWidth: "0", //边框宽度设置1
            borderColor: "none", //设置边框颜色
            padding: 20,

            textStyle: {
              fontSize: 12,
              color: "rgba(209, 212, 220, 1)", //设置文字颜色
            },

            formatter: (params) => {
              const dataIndex = params.dataIndex; // 获取鼠标移到的柱子的索引
              const seriesName = params.seriesName; // 获取柱子所属系列的名称
              const callValue = this.formatNum(this.callInfos.Value);
              const putValue = this.formatNum(this.putInfos.value);
              const value =
                seriesName === "Calls"
                  ? this.formatNum(callSet[dataIndex])
                  : this.formatNum(putSet[dataIndex]); // 获取柱子的值
              const notionalValue =
                seriesName === "Calls"
                  ? callValue[dataIndex]
                  : putValue[dataIndex]; // 获取柱子的值
              const result = `Strike Price:${this.formatNum(params.name)}<br>`;
              const notionalV = `Notional Value[USD]: $${this.formatNum(
                notionalValue
              )}<br>`;

              return `
                <div>
                  <div >
                  ${result}${seriesName}: ${value}<br>${notionalV}
                  </div>
                 

                </div>
              `;
            },
            extraCssText: "background:rgba(61, 63, 71, 1);",
          },
          toolbox: {
            top: 20,
            right: "2%",
            iconStyle: {
              borderColor: "#D1D4DC",
            },
            emphasis: {
              iconStyle: {
                borderColor: "#edbd88", // 鼠标悬停时的图标颜色
              },
            },
            feature: {
              saveAsImage: {
                title: "Download Chart",
                icon: "path://M746.666667 597.333333a149.333333 149.333333 0 0 0-79.36 23.04l-123.093334-123.093333 333.013334-332.8a21.333333 21.333333 0 0 0 0-30.293333 21.333333 21.333333 0 0 0-30.08 0L514.133333 467.2 181.12 134.186667a21.333333 21.333333 0 0 0-30.08 0 21.333333 21.333333 0 0 0 0 30.293333l333.013333 332.8-124.8 124.8A147.626667 147.626667 0 0 0 277.333333 597.333333a149.333333 149.333333 0 1 0 113.92 53.12l122.88-122.88 120.533334 120.533334A149.333333 149.333333 0 1 0 746.666667 597.333333zM277.333333 853.333333a106.666667 106.666667 0 1 1 106.666667-106.666666 106.666667 106.666667 0 0 1-106.666667 106.666666z m469.333334 0a106.666667 106.666667 0 1 1 106.666666-106.666666 106.666667 106.666667 0 0 1-106.666666 106.666666z",
              },
              restore: {
                show: true,
                title: "Restore",
              },
              myFull: myToolFn.myFull(() => this.$refs.chart.$el),
              myDownloadCSV: myToolFn.downloadCSV(() => [
                this.$refs.chart.option.xAxis,
                ...this.$refs.chart.option.series.map((v) => v.data),
              ]),
            },
          },
          grid: {
            top: 80,
            left: "2%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          legend: {
            top: 20,
            textStyle: {
              fontSize: 12,
              color: "#D1D4DC",
            },
            padding: [0, 32, 0, 0],
            itemHeight: 12,
            itemWidth: 12,
            itemGap: 40,
            data: [
              {
                name: "Calls",
                icon: "roundRect",
              },
              {
                name: "Puts",
                icon: "roundRect",
              },
            ],
          },
          xAxis: [
            {
              data: xData,
              type: "category",

              offset: 12,
              axisLabel: {
                rotate: 60,
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              name: "Open Interest",
              axisLabel: {
                formatter: function (v) {
                  if (v > 1000 && v <= 1000000) {
                    return v / 1000 + "K";
                  } else if (v > 1000000 && v <= 1000000000) {
                    return v / 1000000 + "M";
                  } else if (v > 1000000000) {
                    return v / 1000000000 + "B";
                  } else {
                    return v;
                  }
                  // return v + 'K';
                },
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
                margin: 20,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(159, 159, 159, 0.1)",
                },
              },
            },
            {
              type: "value",
              show: false,
            },
          ],
          series: [
            {
              name: "Calls",
              type: "bar",
              data: callSet,
              emphasis: {
                focus: "series",
              },
              itemStyle: {
                color: "rgba(74, 181, 232, 1)",
              },
            },
            {
              name: "Puts",
              type: "bar",
              data: putSet,
              emphasis: {
                focus: "series",
              },
              itemStyle: {
                color: "rgba(234, 204, 174, 1)",
              },
            },
            {
              name: "Total Intrinsic Value",
              type: "line",
              data: [],

              markLine: {
                symbol: [],
                symbolSize: [14, 17],
                label: {
                  show: false,
                },
                data: [],
              },
            },
          ],
          graphic: [
            {
              type: "image",
              id: "logo",
              right: "center",
              bottom: "center",
              z: 0,
              bounding: "all",
              style: {
                image: "echarts-bg_yYgTFo1.png", // 必须是https开头的图片路径地址
                width: 400,
                height: 68,
              },
            },
          ],
        };
      }
    },
    //获取按到日期全部数据
    getPostionExpirationData() {
      const params = {
        currency: this.radio,
        type: "expiration",
      };
      this.axios
        .get("https://trade.rta-finance.com/trade/deribitoption/position/", {
          params,
        })
        .then((res) => {
          if (res.data.code == "0") {
            let calls = res.data.data.calls;
            let puts = res.data.data.puts;
            // calls.sort((a, b) => a.date.localeCompare(b.date));
            // puts.sort((a, b) => a.date.localeCompare(b.date));
            let sortedCalls = calls.slice().sort(function (a, b) {
              return new Date(a.date) - new Date(b.date);
            });
            let sortedPuts = puts.slice().sort(function (a, b) {
              return new Date(a.date) - new Date(b.date);
            });
            this.expirationXData = sortedCalls.map((v) =>
              this.formatDate(Date.parse(v.date), "yyyy-MM-dd")
            );
            this.expirationPut = sortedPuts.map((v) => v.open_sum.toFixed(2));
            this.expirationCall = sortedCalls.map((v) => v.open_sum.toFixed(2));
            this.expirationPutsValue = sortedPuts.map((v) =>
              v.value.toFixed(2)
            );
            this.expirationCallsValue = sortedCalls.map((v) =>
              v.value.toFixed(2)
            );
            this.findInterestByExpiration(
              this.expirationCallsValue,
              this.expirationPutsValue
            );
          }
        })
        .catch((err) => { })
        .finally(() => {
          this.loading = false; // 确保在数据加载完成后隐藏加载动画
        });
    },
    //按到期日数据展示
    findInterestByExpiration() {
      const xData = this.expirationXData;
      const ayData = this.expirationCall;
      const byData = this.expirationPut;
      if (xData != []) {
        this.expirationOption = {
          backgroundColor: "#3d3f47",
          tooltip: {
            trigger: "item",
            borderWidth: "0", //边框宽度设置1
            borderColor: "none", //设置边框颜色
            padding: 20,

            textStyle: {
              fontSize: 12,
              color: "rgba(209, 212, 220, 1)", //设置文字颜色
            },

            formatter: (params) => {
              const dataIndex = params.dataIndex; // 获取鼠标移到的柱子的索引
              const seriesName = params.seriesName; // 获取柱子所属系列的名称
              const callValue = this.formatNum(this.expirationCallsValue);
              const putValue = this.formatNum(this.expirationPutsValue);
              const value =
                seriesName === "Calls"
                  ? this.formatNum(ayData[dataIndex])
                  : this.formatNum(byData[dataIndex]); // 获取柱子的值
              const notionalValue =
                seriesName === "Calls"
                  ? callValue[dataIndex]
                  : putValue[dataIndex]; // 获取柱子的值
              const result = `Expiration: ${this.formatNum(params.name)}<br>`;
              const notionalV = `Notional Value[USD]: $${this.formatNum(
                notionalValue
              )}<br>`;

              return `
                 ${result}${seriesName} : ${value}<br>${notionalV}
              `;
            },
            extraCssText: "background:rgba(61, 63, 71, 1);",
          },
          toolbox: {
            top: 20,
            right: "2%",
            iconStyle: {
              borderColor: "#D1D4DC",
            },
            emphasis: {
              iconStyle: {
                borderColor: "#edbd88", // 鼠标悬停时的图标颜色
              },
            },
            feature: {
              saveAsImage: {
                title: "Download Chart",
                icon: "path://M746.666667 597.333333a149.333333 149.333333 0 0 0-79.36 23.04l-123.093334-123.093333 333.013334-332.8a21.333333 21.333333 0 0 0 0-30.293333 21.333333 21.333333 0 0 0-30.08 0L514.133333 467.2 181.12 134.186667a21.333333 21.333333 0 0 0-30.08 0 21.333333 21.333333 0 0 0 0 30.293333l333.013333 332.8-124.8 124.8A147.626667 147.626667 0 0 0 277.333333 597.333333a149.333333 149.333333 0 1 0 113.92 53.12l122.88-122.88 120.533334 120.533334A149.333333 149.333333 0 1 0 746.666667 597.333333zM277.333333 853.333333a106.666667 106.666667 0 1 1 106.666667-106.666666 106.666667 106.666667 0 0 1-106.666667 106.666666z m469.333334 0a106.666667 106.666667 0 1 1 106.666666-106.666666 106.666667 106.666667 0 0 1-106.666666 106.666666z",
              },
              restore: {
                show: true,
                title: "Restore",
              },
              myFull: myToolFn.myFull(() => this.$refs.chart1.$el),
              myDownloadCSV: myToolFn.downloadCSV(() => {
                console.log(this.$refs.chart1);
                return [
                  this.$refs.chart1.option.xAxis,
                  ...this.$refs.chart1.option.series.map((v) => v.data),
                ];
              }),
            },
          },
          grid: {
            left: "2%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          legend: {
            top: 20,
            textStyle: {
              fontSize: 12,
              color: "#D1D4DC",
            },
            padding: [0, 32, 0, 0],
            itemHeight: 12,
            itemWidth: 12,
            data: [
              {
                name: "Calls",
                icon: "roundRect",
              },
              {
                name: "Puts",
                icon: "roundRect",
              },
            ],
          },
          xAxis: [
            {
              type: "category",
              data: xData,
              axisPointer: {
                type: "shadow",
              },
              axisLabel: {
                showMaxLabel: true,
                rotate: 60,
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLabel: {
                formatter: function (v) {
                  if (v > 1000 && v <= 1000000) {
                    return v / 1000 + "K";
                  } else if (v > 1000000 && v <= 1000000000) {
                    return v / 1000000 + "M";
                  } else if (v > 1000000000) {
                    return v / 1000000000 + "B";
                  } else {
                    return v;
                  }
                  // return v + 'K';
                },
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
                margin: 20,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(255, 255, 255, 0.1)",
                },
              },
            },
          ],
          series: [
            {
              name: "Calls",
              type: "bar",
              data: ayData,
              itemStyle: {
                color: "rgba(74, 181, 232, 1)",
              },
              emphasis: {
                focus: "series",
              },
            },
            {
              name: "Puts",
              type: "bar",
              data: byData,
              itemStyle: {
                color: "rgba(234, 204, 174, 1)",
              },
              emphasis: {
                focus: "series",
              },
            },
          ],
          graphic: [
            {
              type: "image",
              id: "logo",
              right: "center",
              bottom: "center",
              z: 0,
              bounding: "all",
              style: {
                image: "echarts-bg_yYgTFo1.png", // 必须是https开头的图片路径地址
                width: 400,
                height: 68,
              },
            },
          ],
        };
      } else {
        //暂无数据
        this.expirationOption = NOT_DATA_OPTION;
      }
    },
    //获取执行价格全部数据
    getPostionStrikesData() {
      const params = {
        currency: this.radio,
        type: "strikes",
      };
      this.axios
        .get("https://trade.rta-finance.com/trade/deribitoption/position/", {
          params,
        })
        .then((res) => {
          if (res.data.code == "0") {
            let calls = res.data.data.calls;
            let puts = res.data.data.puts;
            this.strikesXData = calls.map((v) => v.option_price);
            this.strikesPut = puts.map((v) => v.open_sum.toFixed(2));
            this.strikesCall = calls.map((v) => v.open_sum.toFixed(2));
            this.findInterestByStrikesn();
          }
        })
        .catch((err) => { })
        .finally(() => {
          this.loading1 = false; // 确保在数据加载完成后隐藏加载动画
        });
    },

    //按执行价格数据展示
    findInterestByStrikesn() {
      let xData = this.strikesXData;
      const ayData = this.strikesCall;
      const byData = this.strikesPut;

      if (xData != []) {
        this.strikesOption = {
          backgroundColor: "#3d3f47",
          tooltip: {
            trigger: "axis",
            borderWidth: "0", //边框宽度设置1
            borderColor: "none", //设置边框颜色
            padding: 16,
            textStyle: {
              fontSize: 12,
              color: "rgba(209, 212, 220, 1)", //设置文字颜色
            },
            tooltip: {
              show: true,
            },
            axisPointer: {
              type: "none",
            },
            extraCssText: "background:rgba(61, 63, 71, 1);",
            formatter: (params) => {
              let aaa = this.kmb(params[0].data);
              let bbb = this.kmb(params[1].data);
              return `
                <div>
                  <div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                   $${this.formatNum(params[0].name)}
                  </div>
                  <div><span style="color:#4AB5E8">${params[0].seriesName
                }: ${aaa}</span><br/>
                 <span style="color:#EACCAE"> ${params[1].seriesName
                }: ${bbb}</span><br/>

                </div>
              `;
            },
          },
          toolbox: {
            top: 20,
            right: "2%",
            iconStyle: {
              borderColor: "#D1D4DC",
            },
            emphasis: {
              iconStyle: {
                borderColor: "#edbd88", // 鼠标悬停时的图标颜色
              },
            },
            feature: {
              saveAsImage: {
                title: "Download Chart",
                icon: "path://M746.666667 597.333333a149.333333 149.333333 0 0 0-79.36 23.04l-123.093334-123.093333 333.013334-332.8a21.333333 21.333333 0 0 0 0-30.293333 21.333333 21.333333 0 0 0-30.08 0L514.133333 467.2 181.12 134.186667a21.333333 21.333333 0 0 0-30.08 0 21.333333 21.333333 0 0 0 0 30.293333l333.013333 332.8-124.8 124.8A147.626667 147.626667 0 0 0 277.333333 597.333333a149.333333 149.333333 0 1 0 113.92 53.12l122.88-122.88 120.533334 120.533334A149.333333 149.333333 0 1 0 746.666667 597.333333zM277.333333 853.333333a106.666667 106.666667 0 1 1 106.666667-106.666666 106.666667 106.666667 0 0 1-106.666667 106.666666z m469.333334 0a106.666667 106.666667 0 1 1 106.666666-106.666666 106.666667 106.666667 0 0 1-106.666666 106.666666z",
              },
              restore: {
                show: true,
                title: "Restore",
              },
              myFull: myToolFn.myFull(() => this.$refs.chart2.$el),
              myDownloadCSV: myToolFn.downloadCSV(() => [
                this.$refs.chart2.option.xAxis,
                ...this.$refs.chart2.option.series.map((v) => v.data),
              ]),
            },
          },
          grid: {
            left: "2%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          legend: {
            top: 20,
            textStyle: {
              fontSize: 12,
              color: "#D1D4DC",
            },
            padding: [0, 32, 0, 0],
            itemHeight: 12,
            itemWidth: 12,
            data: [
              {
                name: "Calls",
                icon: "roundRect",
              },
              {
                name: "Puts",
                icon: "roundRect",
              },
            ],
          },
          xAxis: [
            {
              type: "category",
              data: xData,
              axisPointer: {
                type: "shadow",
              },
              axisLabel: {
                showMaxLabel: true,
                rotate: 60,
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLabel: {
                formatter: function (v) {
                  if (v > 1000 && v <= 1000000) {
                    return v / 1000 + "K";
                  } else if (v > 1000000 && v <= 1000000000) {
                    return v / 1000000 + "M";
                  } else if (v > 1000000000) {
                    return v / 1000000000 + "B";
                  } else {
                    return v;
                  }
                  // return v + 'K';
                },
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
                margin: 20,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(255, 255, 255, 0.1)",
                },
              },
            },
          ],
          series: [
            {
              name: "Calls",
              type: "bar",
              data: ayData,
              stack: "搜索引擎",
              label: {
                show: false,
              },
              itemStyle: {
                color: "rgba(74, 181, 232, 1)",
              },
            },
            {
              name: "Puts",
              type: "bar",
              stack: "搜索引擎",
              label: {
                show: false,
              },
              data: byData,
              itemStyle: {
                color: "rgba(234, 204, 174, 1)",
              },
            },
          ],
          graphic: [
            {
              type: "image",
              id: "logo",
              right: "center",
              bottom: "center",
              z: 0,
              bounding: "all",
              style: {
                image: "echarts-bg_yYgTFo1.png", // 必须是https开头的图片路径地址
                width: 400,
                height: 68,
              },
            },
          ],
        };
      } else {
        //暂无数据
        this.strikesOption = {
          title: NOT_DATA_TITLE,
          xAxis: {
            data: [],
          },
          yAxis: {},
          series: [
            {
              data: [],
            },
          ],
        };
      }
    },
    //按天变化数据显示筛选
    positionChange(item) {
      this.interestArr.prev = item;
      this.getPostionData();
      this.assetsChange();
    },
    //按天变化时间显示筛选
    dateChange(item) {
      this.interestArr.day = item;
      this.positionXData = [];
      this.positionOpenSum = [];
      this.positionValue = [];
      this.positionAllValue = [];
      this.getPostionData();
      this.assetsChange();
    },

    //获取按天变化全部数据
    getPostionData() {
      const params = {
        currency: this.radio,
        // openSum:this.interestArr.prev,
        timeType: this.interestArr.day,
      };
      this.axios
        .get("https://trade.rta-finance.com/trade/deribitoption/position/", {
          params,
        })
        .then((res) => {
          if (res.data.code == "0") {
            let tempData = res.data.data;
            this.positionXData = tempData.all.map((v) => v.query_date);
            this.positionOpenSum = tempData.all.map((v) =>
              v.open_sum.toFixed(2)
            );
            this.positionValue = tempData.all.map((v) => v.value.toFixed(2));

            this.positionAllValue = this.positionOpenSum;
            if (this.interestArr.prev == "open_sum") {
              this.positionAllValue = this.positionOpenSum;
              this.optionText = "# of contracts";
            } else {
              this.positionAllValue = this.positionValue;
              this.optionText = "$ value";
            }
            this.assetsChange(this.optionText);
          }
        })
        .catch((err) => { })
        .finally(() => {
          this.loading2 = false; // 确保在数据加载完成后隐藏加载动画
        });
    },
    //按天变化
    assetsChange() {
      const xData = this.positionXData;
      const ayData = this.positionAllValue;
      let optionText = this.optionText;

      this.assetsOption = {
        backgroundColor: "#3d3f47",
        color: "rgba(0, 174, 147, 1)",
        tooltip: {
          trigger: "axis",
          borderWidth: "0", //边框宽度设置1
          borderColor: "none", //设置边框颜色
          formatter: (params) => {
            let weekDays = this.weekDay(params[0].name);

            let result = `${weekDays},${params[0].name}<br>`;
            if (optionText.length == 0) {
              params.forEach((item) => {
                let aaa = this.kmb(item.value);
                result += `${item.seriesName}[# of contracts] :${aaa}</br>`;
              });
            } else {
              params.forEach((item) => {
                let aaa = this.formatNum(item.value);
                result += `${item.seriesName}[${optionText} ]:${aaa}</br>`;
              });
            }

            return result;
          },
          padding: 20,
          textStyle: {
            fontSize: 12,
            color: "rgba(209, 212, 220, 1)", //设置文字颜色
          },
          tooltip: {
            show: true,
          },
          extraCssText: "background:rgba(61, 63, 71, 1);",
        },
        toolbox: {
          top: 20,
          right: "2%",
          iconStyle: {
            borderColor: "#D1D4DC",
          },
          emphasis: {
            iconStyle: {
              borderColor: "#edbd88", // 鼠标悬停时的图标颜色
            },
          },
          feature: {
            saveAsImage: {
              title: "Download Chart",
              icon: "path://M746.666667 597.333333a149.333333 149.333333 0 0 0-79.36 23.04l-123.093334-123.093333 333.013334-332.8a21.333333 21.333333 0 0 0 0-30.293333 21.333333 21.333333 0 0 0-30.08 0L514.133333 467.2 181.12 134.186667a21.333333 21.333333 0 0 0-30.08 0 21.333333 21.333333 0 0 0 0 30.293333l333.013333 332.8-124.8 124.8A147.626667 147.626667 0 0 0 277.333333 597.333333a149.333333 149.333333 0 1 0 113.92 53.12l122.88-122.88 120.533334 120.533334A149.333333 149.333333 0 1 0 746.666667 597.333333zM277.333333 853.333333a106.666667 106.666667 0 1 1 106.666667-106.666666 106.666667 106.666667 0 0 1-106.666667 106.666666z m469.333334 0a106.666667 106.666667 0 1 1 106.666666-106.666666 106.666667 106.666667 0 0 1-106.666666 106.666666z",
            },
            restore: {
              show: true,
              title: "Restore",
            },
            myFull: myToolFn.myFull(() => this.$refs.chart3.$el),
            myDownloadCSV: myToolFn.downloadCSV(() => [
              this.$refs.chart3.option.xAxis,
              ...this.$refs.chart3.option.series.map((v) => v.data),
            ]),
          },
        },
        grid: {
          left: "2%",
          right: "4%",
          bottom: "20%",
          top: 50,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xData,
          //data: ['2022-09-13', '2022-09-12', '2022-09-11', '2022-09-10', '2022-09-09', '2022-09-08', '2022-09-07'],
          offset: 12,
          axisLabel: {
            textStyle: {
              color: "rgba(209, 212, 220, 1)",
            },
          },
        },
        yAxis: {
          type: "value",
          //type: 'value',
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: ["rgba(159, 159, 159, 0.1)"],
            },
          },
          axisLabel: {
            formatter: function (v) {
              if (v > 1000 && v <= 1000000) {
                return v / 1000 + "K";
              } else if (v > 1000000 && v <= 1000000000) {
                return v / 1000000 + "M";
              } else if (v > 1000000000) {
                return v / 1000000000 + "B";
              } else {
                return v;
              }
              // return v + 'K';
            },
            textStyle: {
              color: "rgba(209, 212, 220, 1)",
            },
            margin: 20,
          },
        },
        // dataZoom: [
        //   {
        //     type: "inside",
        //     start: 0,
        //     end: 100,
        //   },
        //   {
        //     start: 0,
        //     end: 100,
        //   },
        // ],
        series: [
          {
            name: "Open Interest",
            data: ayData,
            type: "line",
            symbol: "circle",
            showSymbol: false,
            lineStyle: {
              color: "rgba(237, 189, 136, 1)",
              width: 1.5,
            },
            itemStyle: {
              normal: {
                color: "rgba(237, 189, 136, 1)",
              },
            },
            areaStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0.1,
                      color: "rgba(237,189,136,0.2000)", // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "rgba(237,189,136,0)", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
          },
        ],
        graphic: graphicBig("echarts-bg_yYgTFo1.png"),
      };
    },
  },
};
</script>
<style lang="scss"  >
.select-container {
  display: flex;
}

.currency-btn {
  margin-bottom: 2rem;
  width: 100%;
  border-bottom: 1px solid rgba(239, 195, 148, 0.3);

  .el-radio-group {
    .el-radio-button {
      margin: 0 32px 0 0;

      .el-radio-button__inner {
        background: transparent !important;
        border: none;
        height: 44px;
        line-height: 44px;
        padding: 0 !important;
        font-size: 20px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #d1d4dc;
        border-radius: 0 !important;
      }

      .el-radio-button__inner:focus {
        border-bottom: 2px solid #edbd88;
        color: #edbd88;
      }

      .el-radio-button__orig-radio:checked+.el-radio-button__inner {
        border-bottom: 2px solid #edbd88;
        color: #edbd88;
      }
    }
  }
}

@import "./scss/option.scss";

canvas {
  padding: 20px;
  /* 调整这个值来增加上边距 */
}
</style>